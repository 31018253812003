import {
  StarBorderOutlined,
  Tune,
  VideocamOffOutlined,
  VideocamOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
} from '@material-ui/icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconButton, Box, Popover } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloseIcon from '@material-ui/icons/Close'
import Thumbnail from '../../../../../../../Components/Thumbnail/Thumbnail'
import {useDrawCanvasToolsContext} from '../../../../../../../Providers/DrawCanvasToolsProvider'
import StreamPlayer from '../../StreamPlayer/StreamPlayer'
import {LiveroomParticipant} from '../hooks/useLiveroomParticipants'
import {useIsMounted} from '../../../../../../../Common/hooks/useIsMounted'
import {StreamState} from '../../../../../../../Common/media/stream'
import {ThumbnailNodeType} from '../../../../../../../Common/drawtools/types'
import {useAudioMixer} from '../../../../../../../Providers/AudioMixerProvider'
import useVolume from '../hooks/useVolume'
import VolumeSlider from '../../../../../../../Components/VolumeSlider/VolumeSlider'
import {CSDErrorCodes, useCSDContext} from '../../../../../../../Providers/CSDProvider'
import Menu from '../../../../../../../Components/Menu/Menu'
import './ParticipantThumbnail.scss'
import useStatusIcons from './hooks/useStatusIcons'
import KickFromRoomConfirmationDialog from '../../../../../../../Components/KickFromRoomConfirmationDialog/KickFromRoomConfirmationDialog'
import { useDialog } from '../../../../../../../Components/RoomDialog/hooks/useDialog'
import { isStreamParticipant } from '../../../../../../../Common/utils/streamUtils'
import { ReactComponent as MuteParticipant } from '../../../../../../../Static/images/microphone_crossed_red.svg'
import { ReactComponent as KickParticipant } from '../../../../../../../Static/images/kick_from_room_red.svg'
import { useGetParticipantConnectivityStats } from '../../../../../../../Components/NetworkConnectivity/hooks/useGetParticipantConnectivityStats'
import { useToggleVideoFeed } from './hooks/useToggleVideoFeed'
import {ParticipantType} from "../../../../../../../Common/janus/clients/liveroom/LiveroomClient";
import {DamStream} from "../hooks/DamStream";

interface LiveRoomThumbnailProps {
  participant: LiveroomParticipant
  isAudioInitiallyMuted: boolean
  isParticipantSpeaking?: boolean
  liveroomAudioEnabled: boolean
  onClick: () => void
  participantsManager
  kickPermissionInfo
  mutePermissionInfo
}

const iconStyles = { style: { height: '12px', width: 'auto' } } // TODO: this sucks

const ParticipantThumbnail = ({
  participantsManager,
  participant,
  isAudioInitiallyMuted,
  isParticipantSpeaking,
  liveroomAudioEnabled,
  onClick: setParticipantAsSpotlight,
  kickPermissionInfo,
  mutePermissionInfo,
}: LiveRoomThumbnailProps): JSX.Element => {
  const isMountedRef = useIsMounted()
  const [iceState, setIceState] = useState<RTCIceTransportState>('new')
  const [isAudioEnabled, setIsAudioEnabled] = useState(!isAudioInitiallyMuted)
  const [isVideoEnabled, setIsVideoEnabled] = useState(true)
  const [isVolumeSliderOpened, setIsVolumeSliderOpened] = useState(false)
  const { setThumbnailNode } = useDrawCanvasToolsContext()
  const [isKickDialogOpen, openKickDialog, closeKickDialog] = useDialog()
  const { setParticipantVolume } = useAudioMixer()
  const { sendEntryToCSD } = useCSDContext()
  const volume = useVolume(participant)
  const thumbnailRef = useRef<HTMLElement | null>(null)

  const setParticipantVolumeHandler = useCallback(
    (volume) => {
      setParticipantVolume(participant.id, volume)
    },
    [setParticipantVolume, participant.id]
  )

  useEffect(() => {
    if (isMountedRef.current) {
      if (participant.stream.state === StreamState.available && participant.type === ParticipantType.DAMStream) {
        const stream = participant.stream as DamStream
        stream.updateAudioStatus(!(liveroomAudioEnabled && isAudioEnabled))
      } else {
        participant.stream.state === StreamState.available &&
        participant.stream.current
            .getAudioTracks()
            .forEach((track) => (track.enabled = liveroomAudioEnabled && isAudioEnabled))
      }
    }
  }, [isMountedRef, participant.stream, liveroomAudioEnabled, isAudioEnabled])

  useEffect(() => {
    if (isMountedRef.current) {
      const isVideoEnabled =
        participant.stream.state === StreamState.available &&
        participant.stream.current.getVideoTracks().every((track) => track.enabled)
      setIsVideoEnabled(isVideoEnabled)
    }
  }, [isMountedRef, participant])

  useEffect(() => {
    // TODO: DAMStreams don't use the icestate. Optional chaining is not ideal.
    const subscription = participant.onIceState?.().subscribe(setIceState)

    return () => {
      subscription?.unsubscribe()
    }
  }, [participant])

  const setVideoNode = useCallback(
    (ref) => setThumbnailNode(participant.id, ref, ThumbnailNodeType.Video),
    [participant, setThumbnailNode]
  )

  const setCanvasRef = useCallback(
    (ref) => setThumbnailNode(participant.id, ref, ThumbnailNodeType.Canvas),
    [participant, setThumbnailNode]
  )

  const isConnectionLost = useMemo(() => {
    const _isConnectionLost = ['failed', 'disconnected'].includes(iceState)
    if (_isConnectionLost) sendEntryToCSD(CSDErrorCodes.serverConnectionLost)

    return _isConnectionLost
  }, [iceState, sendEntryToCSD])

  const toggleAudioState = useCallback((isActive: boolean) => {
    setIsAudioEnabled(isActive)
  }, [])

  const toggleVideoState = useCallback(
    (isActive: boolean) => {
      participant.stream.state === StreamState.available &&
        participant.stream.current.getVideoTracks().forEach((track) => {
          track.enabled = isActive
        })

      setIsVideoEnabled(isActive)
      if (isActive) {
        participant.startReceivingVideo()
      } else {
        participant.stopReceivingVideo()
      }
    },
    [participant]
  )

  const { statusIcons } = useStatusIcons({
    isAudioEnabled,
    isVideoEnabled,
    toggleAudioState,
    toggleVideoState,
    setIsVolumeSliderOpened,
    volume,
  })

  const shouldUseDivider =
    (kickPermissionInfo.isAvailable && kickPermissionInfo.isAllowed) ||
    (mutePermissionInfo.isAvailable && mutePermissionInfo.isAllowed)
  const isParticipantStreamOnly = isStreamParticipant(participant.type) || participant.type === ParticipantType.AppleTV

  const {
    ThumbnailConnectivityStatsEl,
    shouldShowConnectivityStats,
    NetworkConnectivityIndicatorEl,
  } = useGetParticipantConnectivityStats({ participantId: participant.id, isParticipantStreamOnly })

  const menuOptions = [
    {
      text: 'Spotlight',
      icon: <StarBorderOutlined {...iconStyles} />,
      onClick: setParticipantAsSpotlight,
    },
    {
      text: isAudioEnabled ? 'Sound off' : 'Sound on',
      icon: isAudioEnabled ? (
        <VolumeOffOutlined {...iconStyles} />
      ) : (
        <VolumeUpOutlined {...iconStyles} />
      ),
      onClick: () => toggleAudioState(!isAudioEnabled),
    },
    {
      text: isVideoEnabled ? 'Video off' : 'Video on',
      icon: isVideoEnabled ? (
        <VideocamOffOutlined {...iconStyles} />
      ) : (
        <VideocamOutlined {...iconStyles} />
      ),
      onClick: () => toggleVideoState(!isVideoEnabled),
    },
    {
      text: 'Mix sound',
      icon: <Tune {...iconStyles} />,
      onClick: () => setIsVolumeSliderOpened(true),
      divider: shouldUseDivider && !isParticipantStreamOnly,
    },
    {
      text: 'Remove from room',
      icon: <KickParticipant {...iconStyles} />,
      onClick: openKickDialog,
      isAvailable: () =>
        kickPermissionInfo.isAvailable && kickPermissionInfo.isAllowed && !isParticipantStreamOnly,
    },
    {
      text: 'Mute for all',
      icon: <MuteParticipant {...iconStyles} />,
      onClick: () => participantsManager.muteParticipant(participant.id),
      isAvailable: () =>
        mutePermissionInfo.isAvailable && mutePermissionInfo.isAllowed && !isParticipantStreamOnly,
    },
  ]

  const { isReceivingVideo } = useToggleVideoFeed({
    thumbnailRef,
    participant,
    isVideoEnabled,
    isParticipantSpeaking,
  })
  return (
    <>
      <Thumbnail
        isParticipantSpeaking={isParticipantSpeaking}
        name={participant.displayName}
        isNameAlwaysVisible
        isConnectionLost={isConnectionLost}
        canvas={<canvas key={participant.id} ref={setCanvasRef} />}
        onClick={setParticipantAsSpotlight}
        networkConnectivityIndicator={NetworkConnectivityIndicatorEl}
        shouldShowConnectivityStats={shouldShowConnectivityStats}
        thumbnailConnectivityStats={ThumbnailConnectivityStatsEl}
        thumbnailRef={thumbnailRef}
        actions={
          <>
            <Menu
              button={(openMenu) => (
                <IconButton
                  aria-label="settings"
                  onClick={openMenu}
                  className="thumbnail-toggle-button"
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              menuOptions={menuOptions}
              itemStyles={{
                fontSize: '8px',
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            />
          </>
        }
      >
        <Popover
          open={isVolumeSliderOpened}
          onClose={() => setIsVolumeSliderOpened(false)}
          className="volume-slider-popover-overlay"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box width={240} overflow="hidden" padding={2} position="relative">
            <Box position="absolute" top={0} right={0} padding={1.5}>
              <IconButton
                onClick={() => setIsVolumeSliderOpened(false)}
                className="volume-slider-close-btn"
                data-testid="volume-slider-close-btn"
                size="small"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <VolumeSlider
              onVolumeChange={setParticipantVolumeHandler}
              initialValue={volume}
              label="Volume"
            />
          </Box>
        </Popover>

        <StreamPlayer
          stream={participant.stream}
          isReceivingVideo={isReceivingVideo}
          isMuted={false}
          setVideoNode={setVideoNode}
          volume={volume}
        />
        <Box className="status-bar">{statusIcons}</Box>
      </Thumbnail>
      <KickFromRoomConfirmationDialog
        name={participant.displayName}
        onConfirm={async () => {
          await participantsManager.kickParticipant(participant.id)
          closeKickDialog()
        }}
        onCancel={closeKickDialog}
        isOpen={isKickDialogOpen}
      />
    </>
  )
}

export default React.memo(ParticipantThumbnail)
